import React from 'react';
import styles from './Inquirybox.module.css';
import { FaArrowRight } from "react-icons/fa";


function Inquirybox({windowWidth, showInquiryClick}) {
  return (

    <div className={styles.container}>
        {windowWidth <= 480 ? (
            <p className={`${styles.title} noto-sans-kr-semibold`}>경험하는 것이 정답입니다</p>
        ) : (
            <p className={`${styles.title} noto-sans-kr-semibold`}>경험해보는 것이 정답입니다</p>
        )}
        
        <button className={`${styles.actionBtn} noto-sans-kr-bold`} onClick={showInquiryClick}>
            <FaArrowRight style={{ marginRight: '13px' }} /> 
            문의하기</button> 
    </div>
  );
}

export default Inquirybox;