import React from 'react';
import styles from './Cover.module.css';
import coverImage from '../assets/cover.jpeg'; 
import { FaArrowRight } from "react-icons/fa";

function Cover({ windowWidth, showInquiryClick }) {
  return (
    <div className={styles.coverContainer}>
      <img src={coverImage} alt="Cover" className={styles.coverImage} />
      <div className={styles.overlay}></div>
      <div className={`${styles.textContainer} ${windowWidth <= 800 ? styles.textContainer800 : ''}`}>
        <p className={`${styles.textLine1} ${windowWidth <= 480 ? styles.textLine1480 : ''} noto-sans-kr-bold`}>감성으로 이끄는 디지털 전환</p>
        <p className={`${styles.textLine2} ${windowWidth <= 480 ? styles.textLine2480 : ''} noto-sans-kr-light`}>웹, 앱부터 구축형 SI에 이르기까지</p>
        <p className={`${styles.textLine2} ${windowWidth <= 480 ? styles.textLine2480 : ''} noto-sans-kr-light`}>풀스택 역량의 전문가를 만날 때입니다</p>
        <button className={`${styles.actionBtn} noto-sans-kr-bold`} onClick={showInquiryClick}>
            <FaArrowRight style={{ marginRight: '13px' }} /> 
            문의하기</button> 
      </div>
    </div>
  );
}

export default Cover;
