import React, { useEffect, useState } from 'react';
import styles from './Inquiry.module.css';
import { IoMdClose } from "react-icons/io";

function Inquiry({ onCloseInquiry, windowWidth }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    setShow(true); 
  }, []);

  const handleClose = () => {
    setShow(false); 
    setTimeout(() => {
        setName('');
        setAffiliation('');
        setEmail('');
        setPhone('');
        setMessage('');
      }, 500); 
    setTimeout(onCloseInquiry, 350); 
  };

  const sendToTeam = async () => {
    if (!name) {
        document.querySelector('input[placeholder="이름을 입력하세요"]').focus();
        return;
      }
      if (!affiliation) {
        document.querySelector('input[placeholder="소속을 입력하세요"]').focus();
        return;
      }
      if (!email) {
        document.querySelector('input[placeholder="이메일을 입력하세요"]').focus();
        return;
      }
      if (!phone) {
        document.querySelector('input[placeholder="전화번호를 입력하세요"]').focus();
        return;
      }
      if (!message) {
        document.querySelector('textarea[placeholder="문의 내용을 입력하세요"]').focus();
        return;
      }

    await handleClose();
    sendMessageToWebhook(); 
  };

  const sendMessageToWebhook = async () => {
    const messageText = {
      text: `새로운 문의가 들어왔습니다\n\n1. 이름: ${name}\n2. 소속: ${affiliation}\n3. 이메일: ${email}\n4. 전화번호: ${phone}\n5. 문의 내용: ${message}`
    };
  
    try {
      const response = await fetch('https://chat.googleapis.com/v1/spaces/AAAAvZfQItM/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=o-E3LfpHQ7HKOmIIi7kCzzDXyyXPI6S-mi5bkwuRC1Q', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messageText),
      });
  
      if (response.ok) {
        console.log('메시지가 성공적으로 전송되었습니다.');
      } else {
        console.log('메시지 전송에 실패했습니다.', response.status);
      }
    } catch (error) {
      console.error('메시지 전송 중 오류가 발생했습니다.', error);
    }
  };  

  return (
    <div className={`${styles.inquiryPopup} ${show ? styles.show : ''}`}>
        <div className={styles.body}>
            <div className={styles.content}>
                <input type="text" className={styles.inputField} value={name} onChange={(e) => setName(e.target.value)} placeholder="이름을 입력하세요" />
                <input type="text" className={styles.inputField} value={affiliation} onChange={(e) => setAffiliation(e.target.value)} placeholder="소속을 입력하세요" />
                <input type="email" className={styles.inputField} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="이메일을 입력하세요" />
                <input type="tel" className={styles.inputField} value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="전화번호를 입력하세요" />
                <textarea className={windowWidth <= 800 ? styles.inputTextArea800 : styles.inputTextArea} value={message} onChange={(e) => setMessage(e.target.value)} placeholder="문의 내용을 입력하세요" rows="4"/>                
                <button className={`${styles.submitBtn} noto-sans-kr-medium`} onClick={sendToTeam}>팀에게 보내기</button>
                {windowWidth <= 800 && (<button className={`${styles.closeBtn} noto-sans-kr-medium`} onClick={handleClose}>닫기</button>)}
            </div>
            <div className={styles.closeIcon} onClick={handleClose}>
                 {windowWidth > 800 && <IoMdClose size={35} />}
            </div>
        </div>
    </div>
  );
}

export default Inquiry;
