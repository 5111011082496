import React from 'react';
import styles from './Footer.module.css';

function Footer({ windowWidth }) {
  return (
    <div className={styles.container}>
        <p className={`${styles.name} noto-sans-kr-medium`}>주식회사 이모틱스</p>
        <div className={`${styles.divider} ${windowWidth <= 480 ? styles.divider480 : ''}`}></div>
        <div className={`${styles.info} ${windowWidth <= 480 ? styles.info480 : ''}`}>
            <div className={`${styles.left} ${windowWidth <= 480 ? styles.left480 : ''}`}>
                <p className={`${styles.infoText} ${windowWidth <= 480 ? styles.infoText480 : ''} noto-sans-kr-medium`}>서울시 서초구 서초대로 74길 45 엔데버타워 B1</p>
                <p className={`${styles.infoText} ${windowWidth <= 480 ? styles.infoText480 : ''} noto-sans-kr-medium`}>support@emotix.io</p>
            </div>
            <p className={`${styles.infoText} noto-sans-kr-medium`}>대한민국</p>
        </div>
    </div>
  );
}

export default Footer;
