import React from 'react';
import styles from './Every.module.css';
import buildImage from '../Every/assets/build.png'; 
import webImage from '../Every/assets/web.png'; 
import appImage from '../Every/assets/app.png'; 
import planningImage from '../Every/assets/planning.png'; 
import backendImage from '../Every/assets/backend.png'; 
import serverImage from '../Every/assets/server.png'; 
import uiImage from '../Every/assets/ui.png'; 

function Every({windowWidth}) {
  return (
    <div className={styles.container}>
      <img src={buildImage} alt="buildImage" className={styles.buildImage} />
      
      {windowWidth <= 800 ? (
        <p className={`${styles.text} noto-sans-kr-bold`}>모든 것을 디자인하고<br /> 모든 것을 만듭니다</p>  
      ) : (
        <p className={`${styles.text} noto-sans-kr-bold`}>모든 것을 디자인하고 모든 것을 만듭니다</p>
      )}
      {windowWidth <= 480 ? (
        <p className={`${styles.text2} noto-sans-kr-normal`}>기획, 디자인부터<br /> 서비스 구축에 이르기까지<br /> 전 여정을 만들어갑니다</p>
      ) : (
        <p className={`${styles.text2} noto-sans-kr-normal`}>기획, 디자인부터 서비스 구축에 이르기까지 전 여정을 만들어갑니다</p>
      )}
      

      <div className={styles.content}>
        <div className={styles.block}>
          <div className={styles.each}>
            <p className={`${styles.header} noto-sans-kr-bold`}>&#123;Planning&#125;</p>
            <img src={planningImage} alt="planningImage" className={styles.image} />
            <p className={`${styles.explain} noto-sans-kr-normal`}>&lt;// 웹앱 기획&gt;<br />&lt;// 구축형 웹앱 기획&gt;<br />&lt;// 아키텍처 기획&gt;</p>
          </div>
          <div className={styles.each}>
          <p className={`${styles.header} noto-sans-kr-bold`}>&#123;UI/UX&#125;</p>
          <img src={uiImage} alt="uiImage" className={styles.image} />
          <p className={`${styles.explain} noto-sans-kr-normal`}>&lt;// 와이어 프레임&gt;<br />&lt;// 디자인&gt;<br />&lt;// 사용자 경험&gt;</p>
          </div>
          <div className={styles.each}>
          <p className={`${styles.header} noto-sans-kr-bold`}>&#123;Web&#125;</p>
          <img src={webImage} alt="webImage" className={styles.image} />
          <p className={`${styles.explain} noto-sans-kr-normal`}>&lt;// 랜딩 페이지&gt;<br />&lt;// 대시보드&gt;<br />&lt;// 다이내믹 페이지&gt;</p>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.each}>
          <p className={`${styles.header} noto-sans-kr-bold`}>&#123;Mobile App&#125;</p>
          <img src={appImage} alt="appImage" className={styles.image} />
          <p className={`${styles.explain} noto-sans-kr-normal`}>&lt;// 안드로이드 앱&gt;<br />&lt;// iOS 앱&gt;<br />&lt;// 펌웨어&gt;</p>
          </div>
          <div className={styles.each}>
          <p className={`${styles.header} noto-sans-kr-bold`}>&#123;Backend&#125;</p>
          <img src={backendImage} alt="backendImage" className={styles.image} />
          <p className={`${styles.explain} noto-sans-kr-normal`}>&lt;// API 설계&gt;<br />&lt;// 데이터 연산&gt;<br />&lt;// 머신러닝, 딥러닝&gt;</p>
          </div>
          <div className={styles.each}>
          <p className={`${styles.header} noto-sans-kr-bold`}>&#123;Server&#125;</p>
          <img src={serverImage} alt="serverImage" className={styles.image} />
          <p className={`${styles.explain} noto-sans-kr-normal`}>&lt;// 서버 설계&gt;<br />&lt;// 독립서버 구축&gt;<br />&lt;// 서버 호스팅&gt;</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Every;