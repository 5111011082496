import React from 'react';
import styles from './Fullstack.module.css';
import langImage from '../Fullstack/assets/lang.png'; 
import bubbleImage from '../Fullstack/assets/bubble.png'; 
import webflowImage from '../Fullstack/assets/webflow.png'; 
import flutterflowImage from '../Fullstack/assets/flutterflow.png'; 
import htmlImage from '../Fullstack/assets/html.png'; 
import cssImage from '../Fullstack/assets/css.png'; 
import jsImage from '../Fullstack/assets/js.png'; 
import reactImage from '../Fullstack/assets/react.png'; 
import flutterImage from '../Fullstack/assets/flutter.png'; 
import kotlinImage from '../Fullstack/assets/kotlin.png'; 
import swiftImage from '../Fullstack/assets/swift.png'; 
import pythonImage from '../Fullstack/assets/python.png'; 
import javaImage from '../Fullstack/assets/java.png'; 
import nodejsImage from '../Fullstack/assets/nodejs.png'; 
import expressjsImage from '../Fullstack/assets/expressjs.png'; 
import flaskImage from '../Fullstack/assets/flask.png'; 
import springImage from '../Fullstack/assets/spring.png'; 


function Fullstack({windowWidth}) {
  return (
    <div className={styles.container}>
        <img src={langImage} alt="langImage" className={styles.langImage} />
        <p className={`${styles.text} noto-sans-kr-bold`}>Full Stack Developers</p>
        {windowWidth <= 800 && windowWidth > 480 ? (
            <p className={`${styles.text2} noto-sans-kr-normal`}>빠른 개발을 위한 노코드툴 활용부터 직접 개발에 이르기까지<br /> 다양한 언어 및 프레임워크를 다룹니다</p>
        ) : windowWidth <= 480 ? (
            <p className={`${styles.text2} noto-sans-kr-normal`}>빠른 개발을 위한<br /> 노코드툴 활용부터<br /> 직접 개발에 이르기까지<br /> 다양한 언어와<br /> 프레임워크를 다룹니다</p>
        ) : (
            <p className={`${styles.text2} noto-sans-kr-normal`}>빠른 개발을 위한 노코드툴 활용부터 직접 개발에 이르기까지 다양한 언어 및 프레임워크를 다룹니다</p>
        )}
    
        
    
        <div className={styles.content}>
            <div className={styles.block}>
                <div className={styles.each}>
                    <div className={styles.left}>
                        <img src={bubbleImage} alt="bubbleImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Bubble</p>
                    </div>
                    <div className={styles.right}>
                        <p className={`${styles.description} noto-sans-kr-medium`}>노코드로 웹앱을 쉽게 제작</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>드래그 앤 드롭 방식</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>복잡한 기능도 구현 가능</p>
                    </div>
                </div>
                <div className={styles.each}>
                    <div className={styles.left}>
                        <img src={webflowImage} alt="webflowImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Webflow</p>
                    </div>
                    <div className={styles.right}>
                        <p className={`${styles.description} noto-sans-kr-medium`}>노코드로 웹사이트 제작</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>시각적 인터페이스</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>디자이너가 직접 구현 가능</p>
                    </div>
                </div>
                <div className={styles.each}>
                <div className={styles.left}>
                        <img src={flutterflowImage} alt="flutterflowImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Flutter Flow</p>
                    </div>
                    <div className={styles.right}>
                      <p className={`${styles.description} noto-sans-kr-medium`}>드래그 앤 드롭으로 앱 제작</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>비개발자도 모바일 앱 가능</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>노코드로 빠르게 개발</p>
                    </div>
                </div>
                <div className={styles.each}>
                <div className={styles.left}>
                        <img src={htmlImage} alt="htmlImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>HTML</p>
                    </div>
                    <div className={styles.right}>
                       <p className={`${styles.description} noto-sans-kr-medium`}>웹 페이지 구조 정의</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>정적 콘텐츠 구성</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>웹 개발의 기본</p>
                    </div>
                </div>
            </div>

            <div className={styles.block}>
                <div className={styles.each}>
                    <div className={styles.left}>
                        <img src={cssImage} alt="cssImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>CSS</p>
                    </div>
                    <div className={styles.right}>
                        <p className={`${styles.description} noto-sans-kr-medium`}>웹 요소의 스타일을 정의</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>색상, 크기, 레이아웃 설정</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>사용자 친화적 스타일링</p>
                    </div>
                </div>
                <div className={styles.each}>
                    <div className={styles.left}>
                        <img src={jsImage} alt="jsImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Javascript</p>
                    </div>
                    <div className={styles.right}>
                        <p className={`${styles.description} noto-sans-kr-medium`}>웹 페이지에 동적 기능 추가</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>사용자와의 상호작용 구현</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>서버에서도 사용 가능</p>
                    </div>
                </div>
                <div className={styles.each}>
                <div className={styles.left}>
                        <img src={reactImage} alt="reactImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>React</p>
                    </div>
                    <div className={styles.right}>
                      <p className={`${styles.description} noto-sans-kr-medium`}>자바스크립트 라이브러리</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>컴포넌트 기반 재사용 용이</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>반응형 웹앱 개발 강점</p>
                    </div>
                </div>
                <div className={styles.each}>
                <div className={styles.left}>
                        <img src={flutterImage} alt="flutterImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Flutter</p>
                    </div>
                    <div className={styles.right}>
                       <p className={`${styles.description} noto-sans-kr-medium`}>멀티플랫폼 UI 툴킷</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>하나의 코드로 제작</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>빠른 개발과 높은 성능</p>
                    </div>
                </div>
            </div>

            <div className={styles.block}>
                <div className={styles.each}>
                    <div className={styles.left}>
                        <img src={kotlinImage} alt="kotlinImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Kotlin</p>
                    </div>
                    <div className={styles.right}>
                        <p className={`${styles.description} noto-sans-kr-medium`}>Java와 호환되는 언어</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>안드로이드 앱 개발 주력</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>간결한 문법, 코드 효율성</p>
                    </div>
                </div>
                <div className={styles.each}>
                    <div className={styles.left}>
                        <img src={swiftImage} alt="swiftImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Swift</p>
                    </div>
                    <div className={styles.right}>
                        <p className={`${styles.description} noto-sans-kr-medium`}>애플 생태계의 언어</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>애플 앱 개발에 사용</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>서간결하고 빠른 성능 제공</p>
                    </div>
                </div>
                <div className={styles.each}>
                <div className={styles.left}>
                        <img src={pythonImage} alt="pythonImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Python</p>
                    </div>
                    <div className={styles.right}>
                      <p className={`${styles.description} noto-sans-kr-medium`}>간결한 문법의 다목적 언어</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>데이터 과학, 웹 개발 등</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>초보자도 배우기 쉬움</p>
                    </div>
                </div>
                <div className={styles.each}>
                <div className={styles.left}>
                        <img src={javaImage} alt="javaImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>JAVA</p>
                    </div>
                    <div className={styles.right}>
                       <p className={`${styles.description} noto-sans-kr-medium`}>플랫폼 독립적 언어</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>백엔드, 모바일 앱 개발</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>객체지향, 안정성 우수</p>
                    </div>
                </div>
            </div>

            <div className={styles.block}>
                <div className={styles.each}>
                    <div className={styles.left}>
                        <img src={nodejsImage} alt="nodejsImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Node JS</p>
                    </div>
                    <div className={styles.right}>
                        <p className={`${styles.description} noto-sans-kr-medium`}>JavaScript 서버 사용 가능</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>비동기 I/O로 빠른 서버 구현</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>실시간 애플리케이션</p>
                    </div>
                </div>
                <div className={styles.each}>
                    <div className={styles.left}>
                        <img src={expressjsImage} alt="expressjsImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Express JS</p>
                    </div>
                    <div className={styles.right}>
                        <p className={`${styles.description} noto-sans-kr-medium`}>Node.js용 웹 프레임워크</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>빠르게 서버 구축</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>다양한 미들웨어 지원</p>
                    </div>
                </div>
                <div className={styles.each}>
                <div className={styles.left}>
                        <img src={flaskImage} alt="flaskImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Flask</p>
                    </div>
                    <div className={styles.right}>
                      <p className={`${styles.description} noto-sans-kr-medium`}>Python 기반 웹 프레임워크</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>유연한 사용</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>고급 기능까지 가능</p>
                    </div>
                </div>
                <div className={styles.each}>
                <div className={styles.left}>
                        <img src={springImage} alt="springImage" className={styles.stackImage} />
                        <p className={`${styles.stackText} noto-sans-kr-medium`}>Spring</p>
                    </div>
                    <div className={styles.right}>
                       <p className={`${styles.description} noto-sans-kr-medium`}>Java용 백엔드 프레임워크</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>대규모 웹 애플리케이션</p>
                        <p className={`${styles.description} noto-sans-kr-medium`}>모듈이 많아 생산성 높음</p>
                    </div>
                </div>
            
            </div>
        </div>
    </div>
  );
}

export default Fullstack;