import React from 'react';
import styles from './Aboutus.module.css';
import codingImage from '../Aboutus/assets/coding.jpeg'; 

function Aboutus({ windowWidth, showInquiryClick }) {
  return (
    <div className={styles.container}>
        <img src={codingImage} alt="coding" className={styles.codingImage} />
        <div className={styles.overlay}></div>
        <div className={styles.textGroup}>
            <p className={`${styles.text} ${windowWidth <= 800 && windowWidth > 480 ? styles.text800 : windowWidth <= 480 ? styles.text480 : ''} noto-sans-kr-bold`}>&lt;우리는 단순히 코딩을 하는 팀이 아닙니다/&gt;</p>
            <p className={`${styles.text} ${windowWidth <= 800 && windowWidth > 480 ? styles.text800 : windowWidth <= 480 ? styles.text480 : ''} noto-sans-kr-bold`}>&lt;고객의 문제를 해결하기 위해 함께 고민하고/&gt;</p>
            <p className={`${styles.text} ${windowWidth <= 800 && windowWidth > 480 ? styles.text800 : windowWidth <= 480 ? styles.text480 : ''} noto-sans-kr-bold`}>&lt;솔루션을 제공합니다/&gt;</p>
        </div>
    </div>
  );
}

export default Aboutus;
