import React, { useEffect } from 'react';
import styles from './Client.module.css';
import clientImage from '../Client/assets/client.png';
import ceragem from '../Client/assets/ceragem.png';
import cjcgv from '../Client/assets/cjcgv.png';
import coupang from '../Client/assets/coupang.png';
import cu from '../Client/assets/cu.png';
import emart24 from '../Client/assets/emart24.png';
import hyundai from '../Client/assets/hyundai.png';
import innocean from '../Client/assets/innocean.png';
import kdj from '../Client/assets/kdj.png';
import korail from '../Client/assets/korail.png';
import lg from '../Client/assets/lg.png';
import lotte from '../Client/assets/lotte.png';
import paju from '../Client/assets/paju.png';
import sc from '../Client/assets/sc.png';
import starfield from '../Client/assets/starfield.png';

function Client({ windowWidth }) {
  return (
    <div className={styles.container}>
      <img src={clientImage} alt="clientImage" className={styles.clientImage} />
      <p className={`${styles.text} noto-sans-kr-bold`}>
        고객의 가치를 감성적으로 공감합니다
      </p>
      {windowWidth <= 480 ? (
        <p className={`${styles.text2} noto-sans-kr-normal`}>
        많은 고객들이 우리를 신뢰하고<br /> 우리의 역량을 경험하고 있습니다
        </p>
      ) : (
        <p className={`${styles.text2} noto-sans-kr-normal`}>
        많은 고객들이 우리를 신뢰하고 우리의 역량을 경험하고 있습니다
        </p>
      )}
      

      <div className={styles.logoContainer}>
        <div className={styles.logoSlider}>
          <img src={ceragem} alt="ceragem" className={styles.logo} />
          <img src={cjcgv} alt="cjcgv" className={styles.logo} />
          <img src={coupang} alt="coupang" className={styles.logo} />
          <img src={cu} alt="cu" className={styles.logo} />
          <img src={emart24} alt="emart24" className={styles.logo} />
          <img src={hyundai} alt="hyundai" className={styles.logo} />
          <img src={innocean} alt="innocean" className={styles.logo} />
          <img src={kdj} alt="kdj" className={styles.logo} />
          <img src={korail} alt="korail" className={styles.logo} />
          <img src={lg} alt="lg" className={styles.logo} />
          <img src={lotte} alt="lotte" className={styles.logo} />
          <img src={paju} alt="paju" className={styles.logo} />
          <img src={sc} alt="sc" className={styles.logo} />
          <img src={starfield} alt="starfield" className={styles.logo} />
          <img src={ceragem} alt="ceragem" className={styles.logo} />
          <img src={cjcgv} alt="cjcgv" className={styles.logo} />
          <img src={coupang} alt="coupang" className={styles.logo} />
          <img src={cu} alt="cu" className={styles.logo} />
          <img src={emart24} alt="emart24" className={styles.logo} />
          <img src={hyundai} alt="hyundai" className={styles.logo} />
          <img src={innocean} alt="innocean" className={styles.logo} />
          <img src={kdj} alt="kdj" className={styles.logo} />
          <img src={korail} alt="korail" className={styles.logo} />
          <img src={lg} alt="lg" className={styles.logo} />
          <img src={lotte} alt="lotte" className={styles.logo} />
          <img src={paju} alt="paju" className={styles.logo} />
          <img src={sc} alt="sc" className={styles.logo} />
          <img src={starfield} alt="starfield" className={styles.logo} />
        </div>
      </div>
    </div>
  );
}

export default Client;
