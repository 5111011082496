import React, { useState, useEffect } from 'react';
import styles from './TopBar.module.css';
import logoColor from '../assets/emotix_ci_color.png'; 
import logoWhite from '../assets/emotix_ci_white.png'; 

function TopBar() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={styles.topbar}
      style={{
        backgroundColor: scrollPosition > 0 ? '#22b9bb' : 'transparent', 
      }}
    >
      <img 
        src={scrollPosition > 0 ? logoWhite : logoColor} 
        alt="Emotix CI" 
        className={styles.logo} 
      />
    </div>
  );
}

export default TopBar;
