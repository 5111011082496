import React from 'react';
import styles from './Target.module.css';
import startupImage from '../Target/assets/startup.png'; 
import midsizedImage from '../Target/assets/midsized.png'; 
import bigImage from '../Target/assets/big.png'; 

function Target({windowWidth}) {
  return (
    <div className={styles.container}>
        <div className={`${styles.targetGroup} ${styles.group1}`}>
            <p className={`${styles.header} noto-sans-kr-bold`}>For Start-ups</p>
            <p className={`${styles.title} noto-sans-kr-bold`}>MVP로 빠른 시장점검<br />비용 효율적 프로젝트</p>
            <img src={startupImage} alt="startup" className={styles.startupImage} />
                <div className={styles.tags}>
                    <p className={`${styles.tag1} noto-sans-kr-medium`}>start-ups</p>
                    <p className={`${styles.tag1} noto-sans-kr-medium`}>MVP</p>
                    <p className={`${styles.tag1} noto-sans-kr-medium`}>no-code</p>
                    <p className={`${styles.tag1} noto-sans-kr-medium`}>low-cost</p>
                </div>
        </div>
        <div className={`${styles.targetGroup} ${styles.group2}`}>
            <p className={`${styles.header} noto-sans-kr-bold`}>For mid-sized business</p>
            {windowWidth <= 480 ? (
                <p className={`${styles.title} noto-sans-kr-bold`}>스케일업<br />비즈니스 안정화<br />디지털 전환 구축</p>
            ) : (
                <p className={`${styles.title} noto-sans-kr-bold`}>스케일업, 비즈니스 안정화<br />디지털 전환 구축</p>
            )
        }
            <img src={midsizedImage} alt="startup" className={styles.midsizedImage} />
                <div className={styles.tags}>
                    <p className={`${styles.tag2} noto-sans-kr-medium`}>scale-up</p>
                    <p className={`${styles.tag2} noto-sans-kr-medium`}>DX</p>
                    <p className={`${styles.tag2} noto-sans-kr-medium`}>stability</p>
                    <p className={`${styles.tag2} noto-sans-kr-medium`}>cost-effect</p>
                </div>
        </div>
        <div className={`${styles.targetGroup} ${styles.group3}`}>
            <p className={`${styles.header} noto-sans-kr-bold`}>For Entrepreneurs</p>
            <p className={`${styles.title} noto-sans-kr-bold`}>다음 레벨의 프로젝트<br />시스템 통합 구축</p>
            <img src={bigImage} alt="startup" className={styles.bigImage} />
               <div className={styles.tags}>
                    <p className={`${styles.tag3} noto-sans-kr-medium`}>level-up</p>
                    <p className={`${styles.tag3} noto-sans-kr-medium`}>SI</p>
                    <p className={`${styles.tag3} noto-sans-kr-medium`}>full-stack</p>
                    <p className={`${styles.tag3} noto-sans-kr-medium`}>security</p>
                </div>
        </div>
    </div>
  );
}

export default Target;