import React, { useState, useEffect } from 'react'; 
import TopBar from './TopBar/TopBar';
import Cover from './Cover/Cover';
import Inquiry from './Inquiry/Inquiry'; 
import Target from './Target/Target'; 
import Every from './Every/Every'; 
import Fullstack from './Fullstack/Fullstack'; 
import Inquirybox from './Inquirybox/Inquirybox'; 
import Client from './Client/Client'; 
import './App.css'; 
import Aboutus from './Aboutus/Aboutus';
import Footer from './Footer/Footer';

function App() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    const handleContextMenu = (event) => event.preventDefault();
    document.addEventListener('contextmenu', handleContextMenu);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isInquiryOpen, setIsInquiryOpen] = useState(false);

  const showInquiry = () => {
    setIsInquiryOpen(true); 
  };

  const closeInquiry = () => {
    setIsInquiryOpen(false);
  };

  return (
    <div className="App">
      <TopBar windowWidth={windowWidth} />
      <Cover windowWidth={windowWidth} showInquiryClick={showInquiry} /> 
      {isInquiryOpen && <Inquiry windowWidth={windowWidth} onCloseInquiry={closeInquiry} />} 
      <Target windowWidth={windowWidth} /> 
      <Every windowWidth={windowWidth} /> 
      <Fullstack windowWidth={windowWidth} /> 
      <Inquirybox windowWidth={windowWidth} showInquiryClick={showInquiry} /> 
      <Client windowWidth={windowWidth} /> 
      <Aboutus windowWidth={windowWidth} /> 
      <Footer windowWidth={windowWidth} /> 
    </div>
  );
}

export default App;
